import React from "react"
import { Link } from "gatsby"
import {Elements, StripeProvider} from 'react-stripe-elements';

import Layout from "../components/layout"
import SEO from "../components/seo"
import CheckoutForm from '../components/CCForm';

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    return(
      <Layout>
        <SEO title="Order" />
        
        <div className="Order__header">
          <h2>Order</h2>
          <h6 className="subheading">Next Serving: <strong>Tortellini</strong> - Monday October 14, 2019</h6>
        </div>
        
        <div className="container-sm">
          <p>To place an order for the next serving, please submit by July 27th.<br/>
          You must be able to pick up in downtown core.<br/>
          I make all the pasta by hand and imperfections may occur.<br/><br/>
          The only thing I guarantee is that it's fucking good.</p>
        </div>

        <StripeProvider apiKey={process.env.STRIPE_PK}>
          <Elements>
            <CheckoutForm/>
          </Elements>
        </StripeProvider>
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO title="Order" />
      <h2>Order</h2>
    </Layout>
  )
}

export default IndexPage
