import React, { Component } from "react"
import { CardElement, injectStripe } from "react-stripe-elements"
import "./style.css"

class CheckoutForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      fullName: "",
      specialRequests: "",
      completed: false,
      submitButtonDisabled: false,
      step: 1,
      servings: 1,
    }

    this.submit = this.submit.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnCourseSelect = this.handleOnCourseSelect.bind(this)

    this.nonce = Date.now()
  }

  getTotal = () => {
    const { servings } = this.state
    return servings * 10
  }

  selectServingSize = e => {
    this.setState({ servings: e.target.value })
  }

  goToNextStep = () => {
    this.setState({ step: this.state.step + 1 })
  }
  goToPrevStep() {
    this.setState({ step: this.state.step - 1 })
  }

  // Handlers
  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleOnCourseSelect(selectedCourse) {
    // if (!COURSE_OPTIONS.includes(selectedCourse)) {
    //   return;
    // }
    // this.setState({ selectedCourse });
  }

  // Other
  renderError() {
    alert(
      "It looks like something went wrong... Please check to make sure everything is filled out correctly."
    )
  }

  async submit(ev) {
    const {
      email,
      fullName,
      servings,
      specialRequests,
      submitButtonDisabled,
    } = this.state

    if (submitButtonDisabled) {
      return
    }

    this.setState({ submitButtonDisabled: true })

    let { token } = await this.props.stripe.createToken({ email })

    if (!token || !email || !fullName) {
      this.renderError()
      return
    }

    let response = await fetch(`${process.env.API_ROOT}/charge/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productCode: "tortellini",
        fullName,
        email,
        servings,
        specialRequests,
        source: token.id,
        nonce: String(this.nonce),
      }),
    })

    if (response.ok) {
      this.setState({ completed: true })
    }

    if (!response.ok) {
      this.setState({ submitButtonDisabled: false }, this.renderError())
    }
  }

  renderCompleted() {
    return (
      <div style={{ paddingTop: 100, paddingBottom: 100 }}>
        <p className="g-paragraph">
          Thank you for your purchase! You should see a follow-up email from us
          to confirm your purchase.
          <br />
          <br />
          Be sure to check your Junk Folder if you don't see it in your inbox.
          <br />
          <br />
          Please, always feel free to{" "}
          <a href="mailto:me@brandonmowat.com">email me</a> if you have any
          questions.
        </p>
      </div>
    )
  }

  // Render
  render() {
    const { completed, email, fullName, servings, specialRequests } = this.state

    if (completed) {
      return this.renderCompleted()
    }

    return (
      <div className="CheckoutForm container-sm">
        <div className="CheckoutForm__inputs">
          <input
            className="CheckoutForm__inputs__input"
            type="text"
            placeholder="Full Name"
            name="fullName"
            value={fullName}
            onChange={this.handleOnChange}
          />
          <input
            className="CheckoutForm__inputs__input"
            type="text"
            placeholder="Email"
            name="email"
            value={email}
            onChange={this.handleOnChange}
          />
          <div className="CheckoutForm__serving-size">
            <label for="serving-size">Serving size:</label>
            <select
              id="serving-size"
              value={servings}
              onChange={this.selectServingSize}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>
        </div>

        <textarea
          name="specialRequests"
          onChange={this.handleOnChange}
          value={specialRequests}
          placeholder="Special requests?"
        ></textarea>

        <div className="CheckoutForm__inputs">
          <CardElement />
        </div>

        <div className="CheckoutForm__footer">
          <div>
            <h5>Total:</h5>
            <h3>${this.getTotal()}</h3>
          </div>
          <button
            className="Button CheckoutForm__submit complete"
            onClick={this.submit}
          >
            Complete Purchase
          </button>
        </div>
      </div>
    )
  }
}

export default injectStripe(CheckoutForm)
